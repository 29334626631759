import axios from "axios";

export const SendRequest = async (formData) => {
    try {
        const response = await axios.post('https://neosao.com/neosaoadmin/api/enquiry', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
        return response.data;
    } catch (error) {
        throw error.response?.data || error.message;
    }
}


export const fetchPortfolioData = async () => {
    try {
        const response = await axios.get('https://neosao.com/neosaoadmin/api/portfolio');
        return response.data.data;
    } catch (error) {
        throw error.response?.data || error.message;
    }
};

export const JopPostList = async () => {
    try {
        const response = await axios.get('https://neosao.com/neosaoadmin/api/job')
        return response.data;
    } catch (error) {
        throw error.response?.data || error.message
    }
}

export const BlogList = async () => {
    try {
        const response = await axios.get("https://neosao.com/neosaoadmin/api/blog")
        return response.data;
    } catch (error) {
        throw error.response?.data || error.message
    }
}

export const BlogDetailApi = async (blogId) => {
    try {
        const response = await axios.get(`https://neosao.com/neosaoadmin/api/blog/${blogId}`)
        return response.data;
    } catch (error) {
        throw error.response?.data || error.message
    }
}