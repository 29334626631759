import React from 'react'
import InnerHeader from './InnerHeader'
import { useLocation, Link } from 'react-router-dom';
import rocket from "../assets/images/rocket.png";
import satr1 from "../assets/images/star-1.png";
import star2 from "../assets/images/star-2.png";
import cloud1 from "../assets/images/cloud1.png";

const Cart = () => {

    const location = useLocation();

    // Create a function to parse query parameters
    const getQueryParams = () => {
        const searchParams = new URLSearchParams(location.search);
        return {
            product_id: searchParams.get('product_id'),
            product_name: searchParams.get('product_name'),
            product_price: searchParams.get('product_price'),
        };
    };

    const { product_id, product_name, product_price } = getQueryParams();

    return (
        <>
            <InnerHeader />
            <div className="inner-header">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="inner-text">
                                <div className="img">
                                    <img src={rocket} alt='about' />
                                </div>
                                <h5>
                                    Checkout <br />
                                    <span>
                                        <Link to="/">Home</Link> | Your Order
                                    </span>
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="star">
                    <img src={satr1} alt='about' />
                </div>
                <div className="star3">
                    <img src={star2} alt='about' />
                </div>
                <div className="cloud3">
                    <img src={cloud1} alt='about' />
                </div>
            </div>

            <div className="main-inner-services tnb7" id="web-design-development">
                <div className='container'>


                    <div className="row">
                        <div className="col-12 mb-3">
                            <h4>Items in your cart</h4>
                        </div>

                        {
                            product_id && (
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body px-4">
                                            <div className="row">
                                                <div className="col-9">
                                                    <h4>
                                                        {product_name}
                                                    </h4>
                                                </div>
                                                <div className="col-3 text-end">
                                                    <button className="btn btn-danger" type="button">Remove</button>
                                                </div>
                                            </div>
                                            <div className="">
                                                <div>
                                                    <div>Price: INR <strong style={{fontSize:"1.1rem"}}>{product_price}</strong></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                        {
                            product_id && (
                                <div className="col-12 text-end">
                                    <button className='contact_btn'>
                                        Place the order
                                    </button>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>

        </>
    )
}

export default Cart