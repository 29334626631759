import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBhgnewjh08C5vHFoOcvFPcDxNqKhzeOSM",
  authDomain: "blog-post-f67d2.firebaseapp.com",
  projectId: "blog-post-f67d2",
  storageBucket: "blog-post-f67d2.appspot.com",
  messagingSenderId: "435235780556",
  appId: "1:435235780556:web:e54ba19432516a25998f69",
  measurementId: "G-TV8NJ4W3LB"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

// Register user with email and password
const registerWithEmailPassword = async (email, password, username) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    await user.updateProfile({
      displayName: username
    });
    console.log("User registered:", user);
  } catch (error) {
    console.error("Error registering user:", error);
  }
};

// Sign in user with email and password
const signInWithEmailPassword = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    console.log("User signed in:", user);
  } catch (error) {
    console.error("Error signing in:", error);
  }
};

// Sign in with Google
const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    const email = user.email;
    const username = user.displayName;
    const photoURL = user.photoURL; // Get the user's profile picture URL

    localStorage.setItem("email", email);
    localStorage.setItem("username", username || "");
    localStorage.setItem("photoURL", photoURL || ""); // Save profile picture URL

    console.log("User signed in with Google:", user);
  } catch (error) {
    console.error("Error signing in with Google:", error);
  }
};

export { auth, provider, registerWithEmailPassword, signInWithEmailPassword, signInWithGoogle };
