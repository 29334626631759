import React from 'react'
import '../assets/style/loader.css'
import '../assets/style/Responsiveness.css'

export const Loader = () => {
    return (
        <div id="preloader">
            <div id="status">&nbsp;</div>
        </div>
    )
}
