import ScrollToTop from "./components/ScrollToTop";
import Allroutes from "./routes/Allroutes";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import logoImg from "./assets/images/favicon/apple-touch-icon.png";

function App() {
  return (
    <>
      <Helmet>
        <link rel="icon" href={logoImg} alt="neosao" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <title>Neosao Services | Software Company in Kolhapur & Pune</title>
        <meta
          property="og:title"
          content="Neosao Services | Software Company in Kolhapur & Pune"
        />
        <meta
          property="og:description"
          content="Neosao Services is the Most Reliable & an award-winning custom software development company deliver reliable & scalable software development services."
        />
        <meta property="og:image" content={logoImg} alt="neosao" />
        <meta property="og:url" content="https://neosao.com/" />
        <meta property="og:site_name" content="Neosao" />
        <meta property="og:type" content="website" />
      </Helmet>
      <ScrollToTop />
      <Allroutes />
      <ToastContainer position="top-right" autoClose={3000} />
    </>
  );
}

export default App;
